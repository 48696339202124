import React from 'react';
import { Link, Redirect, useParams } from 'react-router-dom'
import { Table, Tag, Space, Card, Popconfirm, Spin, Row, Col, Form, DatePicker, Select, InputNumber, Button, Pagination } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../services/axios'
import SelectSearchInput from '../utils/components/selectSearchInput';
import DatePickerCustom from '../utils/components/datePicker';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
    pagination: {
        pageSize: Number,
        current: Number
    }
}

const ValidateRetrait = () => {
    const { id }: any = useParams()
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [redirect, setRedirect] = React.useState(false)
    const [pagination, setPagination] = React.useState({
        current: 1,
        pageSize: 100
    });
    const [loading, setLoading] = React.useState(false);
    const [devises, setDevises] = React.useState([])
    const [sumRetraitByDevise, setSumRetraitByDevise] = React.useState({})
    const [dateStart, setDateStart] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const [idDevise, setIdDevise] = React.useState(null)

    const [form] = Form.useForm();

    React.useEffect(() => {
        const dateJour = new Date();
        const dateFormated = moment(dateJour).format('YYYY-MM-DD');
        getDevise()
        fetchData({ pagination } as IProps);
        getSumRetraitByDevise(dateFormated, dateFormated)
    }, [])

    const getRandomuserParams = (params: any) => ({
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
    });
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    const fetchData = (params: any) => {
        setLoading(true);
        const dateJour = new Date();
        const dateFormated = moment(dateJour).format('YYYY-MM-DD');
        axios.get(`/operations/type/retrait/${dateFormated}/${dateFormated}`)
            .then(response => {
                // const dataPagination = getRandomuserParams(params)
                
                setData(response.data)
                setLoading(false)
                // setPagination({ ...dataPagination.pagination, total: 20 })
            });
    };

    const getSumRetraitByDevise = (start: any, end: any) => {
        axios.get(`/operations/rapport/sum-retrait/by-devise/${start}/${end}`)
            .then(response => {
                
                setSumRetraitByDevise(response.data);
                setLoading(false)
            });
    }


    const onChange = (name: string, value: any) => {
        form.setFieldsValue({ [name]: value })
        setIdDevise(value);
        setLoading(true)
        axios.get(`/operations/type/retrait/${dateStart}/${dateEnd}/${value}`)
            .then(response => {
                
                setData(response.data)
                setLoading(false)
            });

    }
    function onSearch(val: string) {
        
    }

    const getDevise = () => {
        axios.get('/devises')
            .then(response => {
                
                setDevises(response.data);
                setLoading(false)
            });
    }

    const renderCustomCell = (item: any, name: string) => {
        if (name == 'client') {
            const { client } = item;
            const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
            return clients;
        }
        else if (name == 'devise') {
            const { devise } = item
            return devise.code
        }
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
    };

    const handleOk = (id: number) => {
        
        const dateJour = new Date();
        const dateFormated = moment(dateJour).format('YYYY-MM-DD');
        setConfirmLoading(true);
        const datas = {
            "status": 1
        }
        console.log('enterred8888', id)
        axios.post(`/operations/type/validate/${id}`)
            .then(function (response) {
                
                setRedirect(true)

                axios.get(`/operations/type/retrait/${dateStart}/${dateEnd}`)
                    .then(response => {
                        // const dataPagination = getRandomuserParams(params)
                        
                        setData(response.data)
                        getSumRetraitByDevise(dateStart, dateEnd)
                        setLoading(false)
                        // setPagination({ ...dataPagination.pagination, total: 20 })
                    });
                setConfirmLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setConfirmLoading(false);
            });
    };

    let i = 0;
    const columns = [
        {
            title: "N°",
            key: "index",
            render: (row: any, value: any, index: number = 0) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date_operation',
            key: 'date_operation'
        },
        {
            title: 'N° reçu',
            dataIndex: 'numero_recu',
            key: 'numero_recu'
        },
        {
            title: 'Client',
            key: 'client',
            render: (record: any,) => renderCustomCell(record, 'client'),
        },
        {
            title: 'Déposant',
            key: 'nom_deposant',
            render: (nom_deposant: number, row: any) => (
                <>
                    {row.nom_deposant ? (row.nom_deposant + ' ' + row.tel_deposant) : 'Par le client lui méme'}
                </>
            ),
        },
        {
            title: 'Motif',
            dataIndex: 'motif',
            key: 'motif',
        },
        {
            title: 'Montant',
            dataIndex: 'montant',
            align: 'right',
            key: 'montant',
            render: (montant: any, record: any) => (
                <>
                    <Tag >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + record.devise.code}
                    </Tag>
                    { }
                </>
            ),
        },
        {
            title: 'Taux',
            dataIndex: 'taux',
            align: 'center',
            key: 'taux',
            render: (montant: any, row: any) => (
                <>
                    <Tag >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </Tag>
                </>
            ),

        },
        {
            title: 'Montant Converti',
            dataIndex: 'montant_sortie',
            align: 'right',
            key: 'montant_sortie',
            render: (montant: any, row: any) => (
                <>
                    <Tag color='blue' >
                        {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.devisesortie.code}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: number) => (
                <>
                    {
                        status == 1 ? (
                            <Tag color='success' >
                                validé
                            </Tag>
                        ) : (
                            <Tag color='warning' >
                                en attente
                            </Tag>
                        )
                    }
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => (
                <Space size="middle">
                    {
                        !record.status ? (
                            <Popconfirm
                                title="Confirmez vous cette validation?"
                                onConfirm={() => handleOk(id)}
                                okText={(<><Spin spinning={confirmLoading} indicator={antIcon} />     OUI </>)}
                                cancelText='Non'
                                okButtonProps={{ loading: confirmLoading }}
                                onCancel={handleCancel}
                            >
                                <Button type="primary" >  valider </Button>
                            </Popconfirm>

                        ) :
                            (
                                <Button type="default" ><a>Reçu</a></Button>
                            )
                    }
                </Space>
            ),
        }

    ];

    const onDateChange = (dates: any, dateStrings: any) => {
        setLoading(true)
        
        setDateStart(dateStrings[0])
        setDateEnd(dateStrings[1])
        if (idDevise) {
            axios.get(`/operations/type/retrait/${dateStart}/${dateEnd}/${idDevise}`)
                .then(response => {
                    
                    setData(response.data)
                    setLoading(false)
                });
        }
        else {
            axios.get(`/operations/type/retrait/${dateStrings[0]}/${dateStrings[1]}`)
                .then(response => {
                    
                    setData(response.data)
                    setLoading(false)
                });

        }
        getSumRetraitByDevise(dateStrings[0], dateStrings[1])
    }

    return <>
        <Card size="small" title={<h4 className="text-center">Liste des retraits en attente de validation</h4>} >
            <Row justify='end'>
                <Form layout='inline'>
                    <Form.Item label="Dates">
                        <DatePickerCustom
                            onChange={onDateChange}
                        />
                    </Form.Item>
                    {/* <Form.Item label='Devise'>
                        <SelectSearchInput
                            onChange={(v) => onChange('devise', v)}
                            onSearch={(value) => onSearch(value)}
                            data={devises}
                            label='code'
                            valueLabel='id'
                            placeholder="Selectionnez la devise d'entre"
                        />
                    </Form.Item> */}
                </Form>
            </Row>
            <br />
            <br />

            <Table
                columns={columns as any}
                rowKey={(record: any) => record.login}
                dataSource={data}
                pagination={{ pageSize: 100 }}
                loading={loading}
            // onChange={handleTableChange}
            />
            <Row justify='end'>
                {
                    Object.entries(sumRetraitByDevise).map(
                        (val, index) => <Col span={4}>
                            <Form.Item label={`Total en ${val[0]}`} >
                                <InputNumber
                                    disabled
                                    value={val[1] as number}
                                    style={{ width: '100%', fontSize: 15 }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>
        </Card>
    </>;
}

export default ValidateRetrait;